import styled from 'styled-components';
import { IndexLayout } from '../layouts';
import { NewAppTitle, NewAppText, NewAppButton } from '../components/ui';
// import { ThankYou, ThankYouHeader, NotePad } from '../assets';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { theme } from '../utils/theme';
import { mobileSize } from '../utils';

export default () => {
  return (
    <IndexLayout>
      <Container>
        <Main>
          <Header>Thank You</Header>
            <ThankYouText>
              Click below to download our Business Plan Worksheet
            </ThankYouText>
          {/* <NotePadImage src={NotePad} /> */}
          <ButtonDiv>
            <a href="https://hubs.ly/H0mxcFg0" aria-label="businessplanWorksheet">
              <NewAppButton>
                DOWNLOAD
              </NewAppButton>
            </a>
          </ButtonDiv>
          <ButtonDiv>
            <Link to="/" aria-label="rapptr">
              <NewAppButton>
                Return to Homepage
              </NewAppButton>
            </Link>
          </ButtonDiv>
        </Main>
      </Container>
    </IndexLayout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 3rem;
  background-color: ${theme.BACKGROUND_COLOR};
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.WHITE_COLOR};
`;

const Header = styled(NewAppTitle)`
  margin-top: 10rem;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 6rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`;


const Main = styled.div`
  width: 40%;
  min-width: 500px;
  text-align: center;
  font-family: ${theme.PRIMARY_FONT};

  & > * {
    margin-bottom: 6rem;
  }

  @media ${mobileSize} {
    width: 90%;
    min-width: 100px;
  }
`;

const ThankYouText = styled(NewAppText)`
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  @media ${mobileSize} {
    margin: 200px 0px;
  }
`;
